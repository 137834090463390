<template>
  <Header />
  <div class="min-h-screen bg-white">
    <h1>Smart Contract Saturday</h1>

    <div class="scs-contents">
      <div class="h-full fixed left-0 border-r-2 border-gray-200">
        <div v-for="scs in scs_contents" :key="scs.id" class=" w-full px-2 py-2 hover:bg-blue-200" @click="setSCS(scs)">
          <p class="font-bold">Smart Contract Saturday {{scs.id}}</p>
        </div>
      </div>

      <div class="">
        <div v-for="(block, idx) in selected_scs.qna" :key="idx">
          <p>Q: {{block.q}}</p>
          <p>A: {{block.a}}</p>
        </div>
      </div>

    </div>

  </div>
  <Footer />
</template>
<script>
import Header from '@/components/layouts/sections/Header.vue'
import Footer from '@/components/layouts/sections/Footer.vue'

export default {
  components:{
    Header,
    Footer
  },
  data(){
    return{
      selected_scs: {qna: [
        {q: '', a: '', asker: '', responder: ''}
      ]},
      scs_contents: [
        {timestamp: 1644696428, id: 1, qna: [
          {q: 'Who can make a smart contract?', a: 'This is a smart contract', asker: '', responder: ''}
        ]},
        {timestamp: 1644696428, id: 2, qna: [
          {q: 'What is a smart contract?', a: 'This is a smart contract', asker: '', responder: ''}
        ]},
        {timestamp: 1644696428, id: 3, qna: [
          {q: 'What blockchains have smart contracts?', a: 'This is a smart contract', asker: '', responder: ''}
        ]}
      ]
    }
  },
  methods:{
    setSCS(scs){
      this.selected_scs = scs
    }
  }
}
</script>
<style>
.scs-contents{
  width: 80%;
  margin: 0 auto;
}
</style>
